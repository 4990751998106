<template>
  <div v-if="options" class="flex gap-4">
    <lf-dropdown
      :value="options.search_tier"
      :options="searchTierOptions"
      full-width
      @change="options.search_tier = $event as keyof typeof searchTierOptions"
    />
    <lf-dropdown
      :value="options.search_type"
      :options="searchTypeOptions"
      full-width
      @change="options.search_type = $event as keyof typeof searchTypeOptions"
    />
    <lf-dropdown
      :value="options.is_marketing_use"
      :options="isMarketingUseOptions"
      full-width
      @change="
        options.is_marketing_use = Number(
          $event
        ) as keyof typeof isMarketingUseOptions
      "
    />
  </div>
</template>
<script setup lang="ts">
import LfDropdown from "@/components/ui/inputs/LfDropdown.vue";
import {
  isMarketingUseOptions,
  searchTierOptions,
  searchTypeOptions
} from "@/helpers/constants/lexisNexis";
import type { SelectedOptions } from "@/models/commercialData/lexisnexis/LexisNexisEmailSearchRequest";

const options = defineModel<SelectedOptions>();
</script>
